const defaultLocale = "it"

const UrlScheme = {
  it: {
    applications: "applicazioni",
    products: "prodotti",
    company: "azienda",
    search: "ricerca",
    contacts: "contatti",
    where: "dove-siamo",
  },
  en: {
    applications: "applications",
    products: "products",
    company: "company",
    search: "search",
    contacts: "contacts",
    where: "where-we-are",
  },
  fr: {
    applications: "applications",
    products: "products",
    company: "company",
    search: "search",
    contacts: "contacts",
    where: "where-we-are",
  },
  de: {
    applications: "anwendungen",
    products: "produkte",
    company: "unternehmenienda",
    search: "suche",
    contacts: "fragen-sie-nach-informationen",
    where: "wo-wir-sind",
  },
}

export function getPagePath(page, locale) {
  const pageLocale = locale || page.locale
  let lang = pageLocale === defaultLocale ? "/" : `/${pageLocale}/`
  let path = `${page._allSlugLocales.find(x => x.locale === pageLocale).value}`
  if (page.root) {
    return lang + `${path}/`
  }
  path = `${
    page.treeParent._allSlugLocales.find(x => x.locale === pageLocale).value
  }/${path}`
  if (page.treeParent.root) {
    return lang + `${path}/`
  }
  path = `${
    page.treeParent.treeParent._allSlugLocales.find(
      x => x.locale === pageLocale
    ).value
  }/${path}`
  return lang + `${path}/`
}

export function getPagePathLegacy(page) {
  // console.log(page)
  let lang = page.locale === "it" ? "/" : `/${page.locale}/`
  let path = page.slug
  if (page.root) {
    return lang + path
  }
  path = `${page.treeParent.slug}/${path}`
  if (page.treeParent.root) {
    return lang + path
  }
  path = `${page.treeParent.treeParent.slug}/${path}`
  return lang + path
}

export function getApplicationPath(page, locale) {
  const pageLocale = locale || page.locale
  let lang =
    pageLocale === defaultLocale
      ? "/applicazioni/"
      : `/${pageLocale}/${UrlScheme[pageLocale].applications}/`
  let path = `${page._allSlugLocales.find(x => x.locale === pageLocale).value}`
  return lang + `${path}/`
}

export function getArticlePath(page, locale) {
  return locale === defaultLocale
    ? `/blog/${page._allSlugLocales.find(x => x.locale === locale).value}`
    : `/${locale.toLowerCase()}/blog/${
        page._allSlugLocales.find(x => x.locale === locale).value
      }/`
}

export function getNewsArticlePath(page, locale) {
  return locale === defaultLocale
    ? `/news/${page._allSlugLocales.find(x => x.locale === locale).value}`
    : `/${locale.toLowerCase()}/news/${
        page._allSlugLocales.find(x => x.locale === locale).value
      }/`
}

export function getNewsArticleNewPath(page, category, locale) {
  return locale === defaultLocale
    ? `/${category}/${
        page._allSlugLocales.find(x => x.locale === locale).value
      }`
    : `/${locale.toLowerCase()}/${category}/${
        page._allSlugLocales.find(x => x.locale === locale).value
      }/`
}

export function getProductPath(page, locale) {
  const pageLocale = locale || page.locale
  let lang =
    pageLocale === defaultLocale
      ? `/${UrlScheme["it"].products}/`
      : `/${pageLocale}/${UrlScheme[pageLocale].products}/`
  let path = `${page._allSlugLocales.find(x => x.locale === pageLocale).value}`
  return lang + `${path}/`
}

export function getCategoryPath(page, locale) {
  const pageLocale = locale || page.locale
  let root = getApplicationPath(page.application, pageLocale)
  let path = `${page._allSlugLocales.find(x => x.locale === pageLocale).value}`
  return root + `${path}/`
}

export function getBlogPath(locale) {
  return locale === defaultLocale ? `/blog/` : `/${locale}/blog/`
}

export function getNewsPath(locale) {
  return locale === defaultLocale ? `/news/` : `/${locale}/news/`
}

export function getWherePath(locale) {
  return locale === defaultLocale
    ? `/${UrlScheme[locale].where}/`
    : `/${locale}/${UrlScheme[locale].where}/`
}

export function getHomePath(locale) {
  return locale === defaultLocale ? `/` : `/${locale}/`
}

export function getContactPath(locale) {
  return locale === defaultLocale
    ? `/contatti/`
    : `/${locale}/${UrlScheme[locale].contacts}/`
}

export function getSearchPath(locale) {
  return locale === defaultLocale
    ? `/${UrlScheme[locale].search}/`
    : `/${locale}/${UrlScheme[locale].search}/`
}
