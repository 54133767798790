import React, { useState } from "react"
import { Box, Flex } from "@theme-ui/components"
import { LanguageSwitcherContext } from "../locales/languageSwitcherContext"
import { Link } from "./utils/link"
import { OutboundLink } from "./utils/link"

const LanguageSwitcher = ({ mobile }) => {
  const [show, setShow] = useState(false)
  return (
    <LanguageSwitcherContext.Consumer>
      {({ activeLocale, paths }) => {
        // console.log(paths)
        const activeLink = paths.find(x => x.locale === activeLocale)
        return (
          <Flex
            as={"ul"}
            sx={{
              flexDirection: "row",
              right: 0,
              margin: 0,
              ml: mobile ? 0 : 3,
              listStyle: "none",
            }}
          >
            {paths
              .sort((a, b) => b.locale.localeCompare(a.locale))
              .map((link, index) => (
                <Box
                  as="li"
                  key={index}
                  sx={{ ml: mobile ? 0 : 2 }}
                >
                  <a
                    href={link.value}
                    sx={{
                      whiteSpace: "nowrap",
                      display: "block",
                      borderRadius: "xs",
                      fontWeight:
                        activeLocale === link.locale ? "bold" : "normal",
                      textTransform: "uppercase",
                      textDecoration: "none",
                      color: mobile ? "light" : "inherit",
                    }}
                  >
                    {link.locale}
                  </a>
                </Box>
              ))}
          </Flex>
        )
      }}
    </LanguageSwitcherContext.Consumer>
  )
}

export default LanguageSwitcher
